/*Class Prefix = code-block*/

.code-block-root {
	tab-size: 4;
	border-radius: 6px;
	width: 100%;
	height: 100%;
	border: 1px solid var(--tech-blue);
	margin: auto;
	font-size: 16px;
}
.code-block-root-edit {
	outline: 1px solid var(--tech-blue);
}

.code-block-root pre {
	margin: 0px;
	height: 100%;
	/* background: #fff; */
	display: block;
}

.code-block-input {
	outline: none;
	line-height: 1.1;
	white-space: pre;
	width: 100%;
	color: #000;
	text-align: left;
	display: block;
}
