/*Class Prefix = spinner*/

.spinner-root {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 30px;
	min-height: 30px;
	z-index: 3000;
}

.spinner-root:first-child {
	position: relative;
}