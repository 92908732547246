
:root {
	--ss-hover-box: -1px -1px 4px rgba(0,0,0,.25) inset,
		0px 4px 4px -2px rgba(0,0,0,0.5);

	--cb-width: 260px;
	--toc-width: 250px;
	--nav-height: 50px;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background-color: var(--background-color);
	font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

div::-webkit-scrollbar-track {
  background: transparent !important;
}
div::-webkit-scrollbar-thumb {
  background-color: var(--metallic-matte) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
	margin: 0px;
	padding: 0px;
}

input {
	border: none;
	outline: none;
}

input:focus {
	border: none;
	outline: none;
}

button {
	border: none;
	outline: none;
	font-family: inherit;
}


.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row-center {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.flex-row-reverse {
	display: flex;
	flex-direction: row-reverse;
}

.flex-column-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flex-1 {
	flex: 1;
}

.button-primary, .button-primary-text {
	background: transparent;
	color: var(--secondary);
	border: 2px solid var(--secondary);
	cursor: pointer;
	font-size: 30px;
	border-radius: 4px;
	padding: 5px;
	font-weight: 500;
	transition: color 0.3s, background 0.3s;
	text-align: center;
}
.button-primary:hover, .button-primary-text:hover {
	background: var(--secondary);
	color: var(--light-text-color);
	/*background: transparent;
	color: var(--mid-blue);*/
}
.button-primary:focus, .button-primary-text:focus {
	background: var(--mid-blue);
	color: #fff;
	/*background: transparent;
	color: var(--mid-blue);*/
}
.button-primary:disabled, .button-primary-text:disabled {
	border: 1px solid var(--light-grey);
	color: var(--light-grey);
	background: transparent;
	cursor: default;
}
.button-primary i {
	margin: 0px;
}

.button-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}
.button-icon i {
	margin: 0px;
}

.button-primary-text {
	padding: 5px 8px;
	font-size: 24px;
}

.full-width {
	width: 100%;
}
.full-height {
	height: 100%;
}

.hspace-5 {
	width: 5px;
}

.hspace-10 {
	width: 10px;
}

.hspace-20 {
	width: 10px;
}

.pointer {
	cursor: pointer;
}