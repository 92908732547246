/*Class Prefix = cb*/

.cb-root {
	position: relative;
	width: var(--cb-width);
	transition: 0.5s;
	height: calc(100vh);
	/* background-color: var(--primary); */
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 1;
	/* box-shadow: -6px -5px 24px 4px #777 inset; */

}

.cb-center {
	font-size: 24px;
	display: flex;
	justify-content: center;
}

.cb-root-hidden {
	width: 0px;
}

.cb-root-toggle {
	position: absolute;
	height: 40px;
	right: 0px;
	width: 24px;
	top: calc(50% - 20px);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	cursor: pointer;
	border-left: none;
	transition: 0.5s;
}

.cb-root-toggle-hidden {
	right: -24px;
	background-color: var(--primary);
}

.cb-root-toggle-icon {
	line-height: 1;
	background: transparent;
	color: var(--light-text-color);
	width: 100%;
	transition: 0.3s;
}
.cb-root-toggle-icon:hover {
	color: var(--secondary);
}

.cb-root-toggle i {
	padding: 0px;
	margin: 0px;
	transition: 0.4s;
}
.cb-root-toggle i:hover {
	color: var(--secondary);
}

.cb-avatar-container {
	/*border-radius: 50%;*/
	border-radius: 8px;
	width: calc(var(--cb-width) - 100px);
	height: 70px;
	padding: 5px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	/*border: 1px solid var(--mid-blue);*/
	align-self: center;
	transition: 0.5s;
	color: var(--light-text-color);
	margin: auto;
}

.cb-name-header {
	text-align: center;
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 10px;
}

.cb-avatar-image {
	width: auto;
	height: auto;
	max-width: 80%;
	max-height: 80%;
	cursor: pointer;
}

.cb-content {
	align-self: stretch;
	margin-top: 20px;
	overflow: hidden;
	height: 100%;
}

.cb-setting-header {
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 22px;
	padding: 8px 8px;
	text-transform: capitalize;
	color: var(--mid-blue);
	cursor: pointer;
	transition: 0.3s;
	margin: 5px;
	border: 2px solid var(--secondary);
	border-radius: 4px;
	text-transform: capitalize;
	text-align: center;
}

.cb-setting-header:hover {
	background-color: var(--secondary);
	color: var(--light-text-color);
}

.cb-settings-text-button {
	margin: 10px 0px;
	color: var(--light-text-color);
	cursor: pointer;
}


.cb-settings-text-button:hover {
	color: var(--secondary);
}

.cb-logout-button {
	align-self: center;
	margin: 8px 0 !important;
}

.cb-modal-header {
	text-align: center;
}

.cb-modal-body {
	padding: 10px;
}

.cb-modal-close {
	padding: 10px 0px 0px 0px;
}

.cb-welcome-header {
	margin-top: 50px;
	text-align: center;
	padding: 0 20px;
	color: #fff;
}

.cb-welcome-message {
	text-align: center;
	padding: 0 25px;
	color: #fff;
	font-weight: 600;
}

.cb-version-number {
	margin-top: -10px;
	padding-right: 8px;
	text-align: center;
	color: var(--light-text-color);
	font-size: 12px;
}