/*Class Prefix = study-card*/

.study-card-root {
	position: relative;
	width: 630px;
	height: 368px;
	background: var(--surface-color);
}

.study-card-flip-card {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 630px;
	height: 368px;
	font-size: 1.5rem;
	transform: translate(-50%, -50%);
	transform-style: preserve-3d;
	perspective: 600px;
	transition: 0.5s;
}

.study-card-flip-card-front {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	backface-visibility: hidden;
	transform: rotateX(0deg);
	transition: 0.5s;
}

.study-card-flip-card-back {
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	border-radius: 6px;
	background: #fff;
	backface-visibility: hidden;
	transform: rotateX(180deg);
	transition: 0.5s;;
}

.study-card-flip-card-front-flipped {
	transform: rotateX(-180deg);
}

.study-card-flip-card-back-flipped {
	transform: rotateX(0deg);
}

.study-card-input {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 1.4em !important;
	color: var(--dark-text-color) !important;
	background: var(--surface-color);
	border-width: 2px;
	border-color: var(--slate-grey);
	border-style: solid;
	border-radius: 6px;
	line-height: var(--lined-paper-height);
}