/*Class Prefix = dec*/

.dec-otto-node {
	color: var(--otto-text-color);
	opacity: 0.75;
	padding: 0px 2px 0px 0px;
	border-radius: 4px;
	margin-left: 1px;
}

.dec-link {
	cursor: pointer;
	color: var(--primary);
}
.dec-link:hover {
	text-decoration: underline;
}