/*Class Prefix = ep*/

.ep-root {
	overflow-y: scroll;
	position: relative;
	max-height: 500px !important;
}

.ep-header {
  display: flex;
	align-items: center;
	width: 100%;
	padding: 20px;
  font-size: 24px;
  justify-content: space-between;
	position: sticky;
	z-index: 1000;
	/* width: 100%; */
	background: var(--background-color);
	box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.5);
	top: 0;
	box-sizing: border-box;
}

.ep-kbd {
	background-color: #EEE;
	border-radius: 4px;
	border: 1px solid #b4b4b4;
	color: var(--pirmary);
	display: inline-block;
	padding: 0px 2px;
	white-space: nowrap;
	box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
	font-size: 0.85em;
}

.ep-notebook-boi {
  background-color: #fff;
	max-width: 100%;
	margin: 0px 0px 0px 10px ;
	cursor: text;
	line-height: var(--lined-paper-height);
	font-family: 'Muli', sans-serif;
	font-size: 24px;
	box-sizing: border-box;
	scroll-behavior: smooth;
	
	/*paper lines*/
	position: relative;
	padding: 6px 5px 4px 40px;
	color: #444;
	/*background: -webkit-linear-gradient(top, #d9eaf3 0%, #fff 8%) 0 4px;*/
  
  /* lines */
  background: -webkit-linear-gradient(top, #eee 0%, #fff 8%) 0 4px;
	background-attachment: scroll;
	background-size: 100% var(--lined-paper-height);
}

.ep-notebook-boi:before {
  content: '';
	position: absolute;
	width: 8px;
	border: 1px solid;
	bottom: 0;
	top: 0;
	left: 20px;
	/*border-color: transparent rgba(100, 0, 0, 0.2);*/
	border-color: transparent  var(--light-blue);
}

.ep-notebook-boi ul {
  line-height: var(--lined-paper-height) !important;
  margin: 0px;
}

.ep-notebook-boi > span {
	height: 30px;
}

.ep-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  line-height: var(--lined-paper-height);
}

.ep-toggle {
  margin-left: 50px;

}