/*Class Prefix = control-panel*/

.control-panel-root {
	/* background: var(--light-blue); */
	z-index: 100;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	top: 0px;
	transform: translate(0%, 0%);
	width: 100%;
	/* right: 0px; */
	background: #fff;
}

.control-panel-color-root {
	padding: 4px;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
}

.control-panel-button-group {
	display: inline-flex;
	align-items: center;
	border: 1px solid var(--light-grey);
	border-radius: 4px;
	overflow: hidden;
	margin-left: 5px;
}

.control-panel-button {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	transition: background-color 0.2s, color 0.2s;
	color: var(--dark-grey);
	letter-spacing: 0.5px;
	font-weight: 600;
	position: relative;
	white-space: nowrap;
}
.control-panel-button:hover {
	background-color: var(--primary);
	color: var(--light-text-color);
}

.control-panel-button i {
	width: auto;
	height: auto;
	margin: 0px;
}

.control-panel-button-active {
	background-color: var(--primary);
	color: var(--light-text-color);
}

.control-panel-insert-dropdown, .control-panel-convert-dropdown {
	border-radius: 50px !important;
	border: 1px solid var(--dark-grey) !important;
	color: var(--dark-grey);
	font-weight: 600;
}

.control-panel-insert-dropdown i {
	margin: 0px !important;
}

.control-panel-save-message {
	color: var(--dark-grey);
	margin: 0px 10px;
	width: 130px;
}