
/*Class Prefix = tb*/

.tb-root {
  width: 100%;
  min-height: 240px;
  height: 100%;
  outline: none;
  border: none;
  background: transparent;
  resize: none;
  color: var(--dark-text-color);
}