/* class prefix = cci */

.cci-root {
  display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 24px 0px;
}


.cci-label {
	text-transform: uppercase;
	font-size: 1.5rem;
}

.cci-number {
	font-size: 26px;
	margin-right: 10px;
}

.cci-card {
	width: 300px;
	height: 200px;
	border: 1px solid var(--dark-grey);
	margin: 0px 8px;
	padding: 5px;
	border-radius: 6px;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--surface-color);
  overflow-y: auto;
  text-align: center;
	color: var(--dark-text-color);
}

.cci-card-focused {
	/* box-shadow: 0 3px 6px var(--mid-blue), 0 3px 6px var(--mid-blue); */
	box-shadow: 0 0 10px 1px var(--mid-blue);
}

.cci-mid-button {
	margin: 10px 0px !important;
  color: var(--dark-grey);
  font-size: 16px !important;
  cursor: pointer;
}
