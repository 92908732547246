/*Class Prefix = nav*/

.nav-root {
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--light-text-color);
	width: 100%;
	box-sizing: border-box;
	/* border-bottom: 2px solid var(--primary-dark); */
	height: var(--nav-height);
}

.nav-logo-container {
	width: auto;
	cursor: pointer;
	padding: 5px;
	height: 45px;
}

.nav-logo {
	width: auto;
	height: 100%;
	background: transparent;
}

.nav-text {
	text-decoration: none;
	padding: 10px 20px;
	font-size: 28px;
	color: var(--light-text-color);
}

.nav-text:hover {
	color: var(--light-text-color);
	text-decoration: underline;
}