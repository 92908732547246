/*Class Prefix = te*/

/*.te-root table {
	position: relative;
}*/

table {
	border-spacing: 0px;
	border-collapse: collapse;
	table-layout: fixed;
}
table:first-child {
	width: 100%;
	height: 100%;
}

td {
  border: 1px solid black;
  border-collapse: collapse;
}

.te-root {
	background: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
}

.te-root-edit {
	width: 90%;
	max-width: 90%;
}

.te-cell-root {
	min-width: 80px;
	cursor: cell;
	line-height: var(--lined-paper-height);
	box-sizing: border-box;
	min-height: var(--lined-paper-height);
	flex-direction: row;
	border-color: var(--dark-text-color);
}

.te-cell-span {
	white-space: normal;
	word-wrap: break-word;
	padding: 0px 10px;
	font-size: inherit;
	box-sizing: border-box !important;
	min-height: var(--lined-paper-height);
}


.te-add-row {
	min-width: 100px;
	margin: 5px 0px;
	width: calc(100% - 60px);
	align-self: flex-start;
	align-items: center;
	justify-content: center;
	display: flex;
}

.te-add-column {
	min-height: 50px;
	height: 100%;
	width: 50px;
	margin: 0px 5px;
	align-items: center;
	justify-content: center;
	display: flex;
}

.te-root i {
	margin: 0px;
}

.te-remove-row {
	max-width: 80px;
}

.te-hidden-cell {
	outline: none;
	border: none;
}