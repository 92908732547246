/*Class Prefix = ec*/


.ec-image {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.ec-image-flashcards {
	max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.ec-image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 24px;
  color: var(--primary);
  cursor: pointer;
}

.ec-image-upload-container h1 {
  font-size: 100px;
  margin: 0px;
  line-height: 1;
}