/*Class Prefix = prefs*/

#font-picker {
	box-shadow: none;
	border: 1px solid var(--light-grey);
	margin: 5px 0px;
	border-radius: 4px;
	color: var(--dark-text-color);
}

#font-picker > .dropdown-button {
	background: #fff;
	border-radius: 4px;
}

#font-picker > .font-list {
	background: #fff;
	border-radius: 4px;
	z-index: 2000;
	bottom: 0px;
}

.prefs-no-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.prefs-root {
	/*margin-top: 20px;*/
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--light-text-color);
}

.prefs-name-header {
	font-size: 24px;
	padding: 5px;
	text-align: center;
}

.prefs-name-subheader {
	font-size: 16px;
}

.prefs-name-sub-subheader {
	font-size: 12px;
	padding: 5px 0px 0px 0px;
	max-width: 170px;
}

.prefs-email-subheader {
	font-size: 12px;
	text-align: center;
	margin-bottom: 8px;
}

.prefs-content {
	align-self: flex-start;
	/* margin: 0px 10px; */
	border-left: 1px solid var(--secondary);
	padding: 5px 10px;
	overflow: hidden;
	font-size: 18px;
	transition: height 0.5s;
	width: 100%;
	box-sizing: border-box;
}

.prefs-title {
	font-size: 14px;
	margin-top: 4px;
	width: 100%;
}

.prefs-background-container {
	display: flex;
	align-items: center;
	padding: 10px;
}

.prefs-toggle-container {
	display: flex;
	align-items: center;
	padding: 10px 0px 0px 20px;
}