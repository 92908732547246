

:root {

	/* --primary-light: #c7dccc; */
	--primary-light: #98c7d8;
	/* --primary: #006241; */
	/* --primary: #6FA278; */
	--primary: #53A2BE;
	/* --primary-dark: #52855a; */
	--primary-dark: #326172;

	--secondary-light: #7d9195;
	/* --secondary-light: #ce93d8; */
	--secondary: #27474E;
	/* --secondary: #aa47bc; */
	--secondary-dark: #172b2f;
	/* --secondary-dark: #8d24aa; */

	--dark-text-color: #333333;
	--light-text-color: #ffffff;

	--background-color: #fafafa;
	--surface-color: #fff;

	--otto-text-color: #808080;



	/* --background-color: rgba(220, 199, 170, 0.3); */
	--highlight-color: rgba(76, 110, 158, 1);
	--pop-color: #f95d9b;

	--light-blue: #ddf0ff;
	--silver: #839ca5;
	--mid-blue: #6699cc;
	--tech-blue: #5d88bb;
	--dark-blue: #9ea4ae;

	--white-grey: #c2c5cc;
	--metallic-matte: #dcdcdc;
	--light-grey: #a9a9a9;
	--dark-grey: #696969;
	--slate-grey: #708090;

	--lined-paper-height: 20px;
	--lined-paper-color: #edebff;
	--lined-paper-surface: #fff;
	--lined-paper-margin-color: #ddf0ff;

	--negative-color: #FF928B;

	--tabs-height: 26px;
}

/*
* DARK THEME
*/
[data-theme="dark"] {
	--primary-light: #a4a4a4;
	--primary: #616161;
	--primary-dark: #494949;

	--secondary-light: #87bed2;
	--secondary: #53A2BE;
	--secondary-dark: #4b92ab;

	--dark-text-color: #ccc;
	--light-text-color: #333;

	--lined-paper-color: #ccc;
	--lined-paper-margin-color: #ddf0ff;
	--lined-paper-margin-color: #ddf0ff;
	--lined-paper-surface: #333;

	--background-color: #303030;
	--surface-color: #555;
}	

/*
* Robotic THEME
*/
[data-theme="robotic"] {
	--primary-light: #aebcc4;
	--primary: #78909c;
	--primary-dark: #48565e;

	--secondary-light: #f5a766;
	--secondary: #ef6c00;
	--secondary-dark: #8f4100;

	--dark-text-color: #333;
	--light-text-color: #ffffff;

	--background-color: #e5e5ee;
	--surface-color: #ddd;

	--lined-paper-color: #bbb;
	--lined-paper-margin-color: #ef6c00;
	--lined-paper-surface: #e5e5ee;
}	

/*
* floral THEME
*/
[data-theme="floral"] {
	--primary-light: #ffc8d3;
	--primary: #ffa4b6;
	--primary-dark: #99626d;

	--secondary-light: #739dcc;
	--secondary: #165baa;
	--secondary-dark: #0d3766;

	--dark-text-color: #0b1354;
	--light-text-color: #ffffff;

	--background-color: #fafafa;
}	

/*
* professional THEME
*/
[data-theme="professional"] {
	--primary-light: #bac5c8;
	--primary: #8c9ea3;
	--primary-dark: #545f62;

	--secondary-light: #7f8d9b;
	--secondary: #2a4158;
	--secondary-dark: #192735;

	--dark-text-color: #333;
	--light-text-color: #ffffff;

	--background-color: #e5e5ee;
}

/*
* legacy THEME
*/
[data-theme="legacy"] {
	--primary-light: #a9c7ae;
	--primary: #6FA278;
	--primary-dark: #436148;

	--secondary-light: #cc91d7;
	--secondary: #aa47bc;
	--secondary-dark: #662b71;

	--dark-text-color: #333;
	--light-text-color: #ffffff;

	--background-color: #e5e5ee;
}	

/*
* sleek THEME
*/
[data-theme="sleek"] {
	--primary-light: #f7f7ea;
	--primary: #cece91;
	--primary-dark: #bbbbb3;

	--secondary-light: #eec771;
	--secondary: #c4a35a;
	--secondary-dark: #917844;

	--dark-text-color: #050716;
	--light-text-color: #050716;

	--background-color: #e5e5ee;
}	

/*
* sunshine THEME
*/
[data-theme="sunshine"] {
	--primary-light: #f5d1a5;
	--primary: #f3d2ab;
	--primary-dark: #a38663;

	--secondary-light: #f37222;
	--secondary: #d2601a;
	--secondary-dark: #a34a13;

	--dark-text-color: #333;
	--light-text-color: #ffffff;

	--background-color: #e5e5ee;
}

/*
* miami THEME
*/
[data-theme="miami"] {
	--primary-light: #fcb3a9;
	--primary: #ff9a8d;
	--primary-dark: #c9695c;

	--secondary-light: #c4f1f8;
	--secondary: #aed6dc;
	--secondary-dark: #8bacb1;

	--dark-text-color: #ff9a8d;
	--light-text-color: #ffffff;

	--background-color: #ffffff;
}

/*
* rave THEME
*/
[data-theme="rave"] {
	--primary-light: #fa75b5;
	--primary: #da68a0;
	--primary-dark: #af5480;

	--secondary-light: #97f8bb;
	--secondary: #77c593;
	--secondary-dark: #568f6b;

	--dark-text-color: #da68a0;
	--light-text-color: #ffffff;

	--background-color: #77c593;
}

/*
* whiskey THEME
*/
[data-theme="whiskey"] {
	--primary-light: #f7e0c7;
	--primary: #ddc3a5;
	--primary-dark: #b39d83;

	--secondary-light: #973333;
	--secondary: #652121;
	--secondary-dark: #311010;

	--dark-text-color: #ddc3a5;
	--light-text-color: #ffffff;

	--background-color: #ffffff;
}

/*
* rustic THEME
*/
[data-theme="rustic"] {
	--primary-light: #b89c94;
	--primary: #895a4d;
	--primary-dark: #52362e;

	--secondary-light: #b6b69f;
	--secondary: #85865f;
	--secondary-dark: #505039;

	--dark-text-color: #333;
	--light-text-color: #fff;

	--background-color: #fafafa;
}

/*
* yuki THEME
*/
[data-theme="yuki"] {
	--primary-light: #492550;
	--primary: #301934;
	--primary-dark: #211124;

	--secondary-light: #b80606;
	--secondary: #990000;
	--secondary-dark: #750101;

	--dark-text-color: #301934;
	--light-text-color: #ffffff;

	--background-color: #f3f3ea;
}

/*
* mike's THEME
*/
[data-theme="mike"] {
	--primary-light: #a7c0cd;
	--primary: #78909c;
	--primary-dark: #4b636e;

	--secondary-light: #ff9d3f;
	--secondary: #ef6c00;
	--secondary-dark: #b53d00;

	--dark-text-color: #000000;
	--light-text-color: #ffffff;

	--background-color: #f3f3ea;
}

/*
* trev's THEME
*/
[data-theme="trev"] {
	--primary-light: #ffe7a1;
	--primary: #d8b572;
	--primary-dark: #a58545;

	--secondary-light: #c7e5fc;
	--secondary: #96b3c9;
	--secondary-dark: #678398;

	--dark-text-color: #000000;
	--light-text-color: #ffffff;

	--background-color: #f3f3ea;
}

[data-theme="clemson"] {
	--primary-light: #F78433;
	--primary: #F56600;
	--primary-dark: #AB4700;

	--secondary-light: #745799;
	--secondary: #522D80;
	--secondary-dark: #391F59;

	--dark-text-color: #000000;
	--light-text-color: #ffffff;
}