/*Class Prefix = ee*/

.ee-root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ee-input {
	margin: 10px;
	padding: 4px;
	width: 100%;
	text-align: center;
	border: 1px solid var(--light-blue);
	border-radius: 6px;
	padding: 15px 0px;
	transition: box-shadow 0.3s;
}
.ee-input:focus {
	border: 1px solid var(--light-blue);
	box-shadow: 0px 2px 4px 0px #ccc;
}

.ee-presets-container {
	background: #fff;
	width: 100%;
	border: 1px solid var(--light-blue);
	border-radius: 6px;
	padding: 10px;
	display: flex;
}

.ee-presets-container-inner {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	max-height: 150px;
	overflow-y: scroll;
	padding: 0px 10px;
	position: relative;
	overflow-x: hidden;
}
.ee-presets-container-inner::before {
	height: 40px;
	width: 100%;
	content: '';
}
.ee-presets-container-inner::-webkit-scrollbar {
	width: 4px;
	margin: 2px;
	border-radius: 50%;
}

.ee-presets-container-inner-title {
	position: absolute;
	background: #fff;
	z-index: 100;
	box-shadow: 0px 4px 5px -4px #ccc;
	width: 90%;
	padding: 0px 5px;
}

.ee-katex-container {
	/* border: 1px solid var(--light-blue); */
	border-radius: 6px;
	padding: 5px 20px;
	/* background: #fff; */
	cursor: cell;
}

.ee-katex-container-edit {
	margin-top: 10px;
	border: 1px solid black;
	border: 1px solid var(--metallic-matte);
	padding: 10px 20px;
	border-radius: 6px;
	background: var(--light-blue);
	position: relative;
}

.ee-katex-container-edit::before {
	content: ' ';
	position: absolute;
	top: -16px;
	left: calc(50% - 16px);
	height: 0px;
	width: 0px;
	border-left: 16px solid transparent;
	border-right: 16px solid transparent;
	border-bottom: 16px solid var(--light-blue);
}

.ee-katex-container-empty {
	width: 150px;
	font-size: 1.2rem;
	height: 50px;
	/* background: #fff; */
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 6px;
	/* border: 2px solid var(--light-blue); */
}
.ee-katex-container-empty::before {
	content: 'click to edit';
}

.ee-katex-button {
	position: relative;
	padding: 5px;
	/* border: 1px solid var(--light-blue); */
	/* background: #fff; */
	border-radius: 6px;
	cursor: pointer;
	transition: box-shadow 0.2s, background 0.2s;
	box-shadow: 0px 1px 4px 0px #ccc;
	margin: 5px;
	font-size: 16px;
}
.ee-katex-button:hover {
	box-shadow: 0px 2px 4px 1px #ccc;
	background: #ccc;
}
