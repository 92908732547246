/*Class Prefix = brs*/

.brs-dashed-list {
  list-style-type: none;
  padding-left: 0px;
}

.brs-dashed-list > li:before {
  content: "–"; /* en dash */
  position: absolute;
  margin-left: -1.1em; 
}

.brs-text-align-left {
	text-align: left;
}

.brs-text-align-center {
	text-align: center;
}

.brs-text-align-right {
	text-align: right;
}

brs-atomic-container {
	line-height: var(--lined-paper-height);
}