/*Class Prefix = toc*/

.toc-root {
	position: relative;
	width: var(--toc-width);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px 10px 10px 10px;
	height: 100%;
	box-sizing: border-box;
}
.toc-root::after {
	content: ' ';
	position: absolute;
	right: 0px;
	height: 100%;
	top: 0px;
	width: 10px;
	border-right: 1px solid var(--light-blue);
	border-left: 1px solid var(--light-blue);
}

.toc-header {
	font-size: 22px;
	border-bottom: 2px solid var(--light-blue);
	width: 100%;
	line-height: 1.1;
	padding-left: 5px;
	color: var(--dark-text-color);
	direction: ltr;
}


.toc-sub-list {
	margin-left: 20px;
}

.toc-root ol {
	/* counter-reset: item; */
	padding: 2px;
	max-width: 100%;
	height: 90%;
	direction: ltr;
	box-sizing: border-box;
}

.toc-wrapper-list {
	height: 100%;
	width: 100%;
	overflow: auto;
	direction: rtl;
}

.toc-chapter, .toc-subchapter {
	color: var(--dark-text-color);
}
.toc-chapter:hover {
	color: var(--dark-blue);
}
.toc-subchapter:hover {
	color: var(--mid-blue);
}

.toc-root li {
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 14px;
	max-width: 100%;
	cursor: pointer;
	font-weight: 500;
}

.toc-root li:before {
	/* content: counters(item, ".") " "; */
	/* counter-increment: item */
	content: "‣ ";
}